.mission--V2__buttons__parameters{
  margin: 5px;
  background-color: white;
  opacity: 0.3;
  height: 33px;
  width: 33px;
  border-radius: 20px;
  border: none;
  transition: all 0.1s;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #44c5f3;
    opacity: 1;
    & img {
      filter: invert(100%);
    }
  }
  & img {
    width: 26px;
    height: 26px;
  }
  &--small-pic img {
    width: 22px;
    height: 22px;
  }
  &--white {
    &:hover {
      background-color: white;
      & img {
        filter: invert(0%);
      }
    }
  }
}