
table, th, td {
  border: 1px solid black;
  padding:15px;
}

.window-onboarding__alert{
  background-color: red;
  height: 15px;
  width:15px;
  border-radius: 50%;
  margin:10px;
}