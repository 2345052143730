
.text--center {
  text-align: center;

  &--alert{
    color:red;
    font-size: 0.8rem;
  }

  &--forced {
    text-align: center !important;
  }

}

.text--i {
  font-style: italic;
}


.text--bold {
  font-weight: bold;
}

.text--right {
  text-align: right;
}

.text--left {
  text-align: left;
}

.text--justify {
  text-align: justify;
}

.text--hollow {
  opacity: 0.35;
}

.text--hollow--hover {
  opacity: 0.35;
  transition: all 0.3s;
  &:hover {
    opacity: 0.85;
  }
}

.text--hide {
  opacity: 0;
}

.text--transition {
  transition: all 0.5s;
}

.text--grey {
  color: #bbb;
}

.text--grey2 {
  color: #f1f1f1;
}

.text--dark-grey {
  color: #a0a0a0;
}

.text--white {
  color: #ffffff;
}


.text--magnified1x {
  font-size: 1.1rem;
}

.text--magnified11x {
  font-size: 1.16rem;
}

.text--magnified15x {
  font-size: 1.22rem;
}

.text--magnified2x {
  font-size: 1.3rem;
}

.text--magnified3x {
  font-size: 1.5rem;
}

.text--minified1x {
  font-size: 0.95rem;
}

.text--minified2x {
  font-size: 0.9rem;
}

.text--minified3x {
  font-size: 0.82rem;
}

.text--minified4x {
  font-size: 0.78rem;
}

.modern-link {
  text-decoration: underline;
  color: #3898ec;
  transition: color 0.3s;
  &:hover {
    color: #3898ec;
  }
}

.modern-link--grey {
  text-decoration: underline;
  color: #bbb;
  transition: color 0.2s;
  &:hover {
    color: #999;
  }
}

.modern-link--white {
  text-decoration: underline;
  color: white;
  transition: color 0.2s;
  &:hover {
    color: white;
  }
}

.modern-link--grey-dark {
  text-decoration: underline;
  color: #888;
  transition: color 0.2s;
  &:hover {
    color: #555;
  }
}

.img--small {
  height: 15px;
  width: 15px;
}

.img--btn {
  height: 22px;
  width: 22px;
}

.img--medium {
  display: inline !important;
  width: 28px !important;
  height: 28px !important;
}

.lato {
  //font-family: 'Lato', sans-serif;
}

.relative {
  position: relative;
}

.list-alternate-background {
  & li {
    background: white;
    padding: 8px;
  }
  & li:nth-child(odd) { background: #f6f6f6; }
}

.text--new-blue {
  color: #44c5f3;
}

.info-box {
  margin: auto;
  background-color: #ade3ff;
  padding: 40px 40px;
  font-size: 0.9rem;
  border-radius: 20px;
  width: 95%;
  max-width: 800px;
  & * {
    font-size: 0.9rem;
  }
}

.new-ul {

  display: list-item !important;
  list-style-type: circle !important;

  & li {
    display: list-item !important;
    list-style-type: circle !important;
    margin: 6px auto;
  }
}

.btn--hollow{
  background-color: transparent;
  border:none;
  &:disabled{
    opacity: 0.4;
  }
}

.hollow {
  opacity: 0.5;
}

.column{
  display:flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}


.row{
  display:flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}



.margin30px{
  margin:30px;
}

.margin5px{
  margin:5px;
}

.margin15px{
  margin:15px;
}

.space--small {
  margin: 5px;
}

.text--white {
  color: white;
}

.svg--white {
  filter: invert(100%);
}

.comment {
  width: 90%;
  max-width: 590px;
  margin: auto;
  padding: 10px 20px;
  border-radius: 30px;
  border: 1px solid #bbb;
}

.text--blue {
  color: #3898ec;
}

.text--green {
  color: #2ec43d;
}

.text--orange {
  color: #ec8038;
}

.blur {
  filter: blur(7px);
}

.input3 {
  width: 95% !important;
  max-width: 550px;
  //height:60px !important;
  border: 1px solid #ccc !important;
  background-color: white !important;
  border-radius: 30px !important;
  margin: 7px auto !important;
  padding: 10px 18px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  @include smartphones-only {
    width: 100% !important;
  }
  &__label {
    //background-color: cyan;
    width: 160px;
    font-size: 0.85rem;
    font-weight: bolder;
    color: #666;
    text-align: left;
    @include smartphones-only {
      width: 100px;
    }
  }
  &__input {
    //background-color: salmon;
    width: 100%;
    border: none !important;
  }
}

.text--small {
  font-size: 0.95rem;
}