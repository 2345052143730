.navbar {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 80px;
  width: 100vw;
  padding: 23px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: #121217;
  z-index: 5000000;
  color:white;

  &__left {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  &__right {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
  }

  &__logo {
    font-family: 'geomanistbold', sans-serif;
    font-size: 1.5rem;

    & span {
      font-size: 1rem;
    }
  }

  &__btn{
      background-color: #fff;
      color: #3898ec;
    padding: 10px 15px;
    border-style: none;
    border-radius: 30px;
    font-size: 17px;
    font-weight: 700;
    cursor: pointer;
    margin-right: 10px;
  }

  &__btn2 {
    background: transparent;
    color: white;
    padding: 10px 15px;
    border-style: none;
    border-radius: 30px;
    font-size: 17px;
    font-weight: 700;
    cursor: pointer;
    margin-right: 10px;
    transition: all 0.5s;
    &:hover {
      background-color: white;
      color: black;
    }
  }
}

.navbar__position {
  background-color: white;
  color: black;
  padding: 10px 20px;
  font-size: 1.2rem;
  border-radius: 30px;
  margin-left: 30px;
}

.navbar__center {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.subnav {
  text-align: center;
}

.subnav__button {
  border: none;
  background: transparent;
  opacity: 0.4;
  font-size: 1.3rem;
  font-weight: bolder;
  margin: 0 25px;
  transition: all 0.3s;

  &--progress {
    color: #3898ec;
  }
  &--recruited {
    color: #2ec43d;
  }
  &--canceled {
    color: #E21C64;
  }
  &--selected {
    opacity: 1;
  }
}