.window-stats-mission {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.window-stats-mission-item{
    background-color: #f6f2ed;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    margin: 20px;
    border-radius: 20px;
    min-height: 100px;
    width: 170px;
    padding: 10px;

  }

  .window-stats-mission-item--title{
    font-size: 0.9rem;
    color: #aaa;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 10px;
  }

  .window-stats-mission-item--date{
    font-family: 'geomanistblack', 'Lato', sans-serif;
    font-size: large;
    font-weight: bold;
  }
