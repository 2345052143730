.client-page__missions{

}

.client-page__mission__items {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}

.client-page__mission{
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  //background-color: #a1c4e4 ;
  background-color: white;
  border-radius: 25px ;
  margin: 30px auto;
  padding: 19px 22px;
  max-width: 1100px;
  border: 2px solid white;

  @include smartphones-and-tablets-only {
    flex-flow: column;
    justify-content: center;
  }

  &--research {
    border: 2px solid #3a76ac;
  }
}

.client-page__mission__left {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.client-page__mission__candidates-number {
  width: 90px;
  height: 60px;
  border-right: 2px solid grey;

  margin-right: 30px;
  padding-right: 10px;

  color: #ccc;
  font-weight: bolder;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-size: 2rem;

  & span {
    font-size: 1rem !important;
  }
}

.client-page__mission__item {
  //margin: 0 10px;
  min-width: 150px;
}

.client-page__title{
  font-weight: bold;
  text-align: center;
  font-size: xx-large;
}

.client-page__btn {
  display: inline-block;
  padding: 10px 22px;
  border-style: none;
  border-radius: 30px;
  background-color: #3898ec;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  cursor: pointer;
  margin: 5px;

  &:disabled {
    opacity: 0.4;
  }
}

.client-page__avatar{
  width:70px;
  height: 70px;
  border-radius: 45px;
  object-fit: cover;
}


.client-page__candidate{
  margin:12px;
  padding: 15px;
  border-radius: 25px;
  background-color: #eef7ff;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  & > * {
    margin: 0 20px;
  }
}

.client-page__candidate__name {
  font-size: 1.2rem;
  font-weight: bolder;
  color: #3898ec;
}

.client-page__candidate__block__name {
  min-width: 250px;
}

.client-page__candidate--recruited{
  background-color: #84d481;
}

.client-page__evaluation{
  width:150px;
}

.client-page__mission__status {

  padding: 10px 15px;
  color: white;
  border-radius: 25px;
  font-weight: bolder;
  font-size: 0.95rem;

  &--blue {
    color: #3898ec;
  }
  &--green {
    color: #53ec38;
  }
  &--orange {
    color: #ec6238;
  }
}

.client-page__date {
  background-color: #eee;
  font-size: 0.9rem;
  padding: 5px 10px;
  border-radius: 20px;
}

.client-page__date__job-title {
  color: #3898ec;
  font-size: 1.2rem;
  font-weight: bolder;
}

.client-page__filters{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.client-page__filter{
  padding: 10px 20px;
  border-radius: 30px;
  margin: 0 10px 0 10px;
  border:1px solid #ccc;
  background-color: white;
}

.client-page__mission__avatars {

  & img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin: 7px 5px;
    object-fit: cover;
  }
  //border:1px solid black;
}

.client-page__candidate-recruited{
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px;
  background-color: white;
  border-radius: 20px;
  width:250px;
  height:300px;
  padding:30px;
}

.client-page__candidates-recruited{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.client-page__candidate-recruited__positionWanted{
  text-align: center;
}

.filter-title {
  padding-left: 10px;
  color: #3898ec;
}