
.window-container {

  z-index: 30000000;
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(47, 47, 47, 0.51);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  transition: background-color 0.15s;
  //overflow-y: scroll;
  //overflow-x: scroll;

}


.window::-webkit-scrollbar {
  display: none;
}

.window {

  padding: 22px 25px 40px 25px;
  width: 500px;
  max-height: 96vh;
  background-color: white;
  //overflow-x: scroll;
  border-radius: 20px;
  overflow-y: auto;


  @include smartphones-only {
    padding: 22px 30px 50px 30px;
    width: 90vw;
    max-height: 90vh;
    margin: auto;
  }

  &--big {
    width: 95%;
    max-width: 960px;
    max-height: 94vh;
    //height: 94vh;
    @include smartphones-only {
      width: 90vw;
      max-height: 94vh;
      margin: auto;
    }
  }

  &--bigger {
    width: 95%;
    max-width: 1060px;
    height: 94vh;
    @include smartphones-only {
      width: 90vw;
      max-height: 94vh;
      margin: auto;
    }
  }

  &__section {
    margin: 40px auto;
  }

}

.window {

  &__header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 12px;

    &__logo {
      font-family: 'Pacifico', cursive;
      font-size: 1rem;
      //font-weight: bold;
      color: #3a76ac;;
    }
  }

  &__title {
    text-align: center;
    margin: 0px auto 15px auto;
    font-weight: 800;
    font-size: 1.3rem;
    color: #444;
  }

}

.window {
  &--appear {
    animation: window-appear2 0.3s forwards;
  }
  &--disappear {
    animation: window-disappear2 0.3s forwards;
  }
}

@keyframes window-appear {
  0% {
    opacity: 0;
    right: -15px;
    transform: perspective(1000px) rotateY(10deg);
  }
  100% {
    opacity: 1;
    right: 0px;
    transform: perspective(1000px) rotateY(0deg);
  }
}

@keyframes window-appear2 {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes window-disappear {
  0% {
    opacity: 1;
    right: 0px;
    transform: perspective(1000px) rotateY(0deg);
  }
  100% {
    opacity: 0;
    right: -15px;
    transform: perspective(1000px) rotateY(10deg);
  }
}

@keyframes window-disappear2 {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}

.window-container {
  &--appear {

  }
  &--disappear {
    background-color: #0000;
  }
}

.window-content {

}

.black-border {
  background-color: #333;
  padding: 35px 20px;
  //border-radius: 10px;
  &--inside {
    background-color: #f6f6f6;
    border-radius: 10px;
    overflow: hidden;
  }
  &__cv-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
}

.window__navbar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
}

.window--restrained {

  width: 95%;
  max-width: 620px;
  margin: auto;

  @include smartphones-only {
    width: 95%;
  }
}
.window__content{

}