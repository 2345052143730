
.no-video {
  width: 100%;
  height: 450px;
  background-color: #222222;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  border-radius: 18px;


  position: relative;

  overflow: hidden;

  & .no-video__img {
    border-radius: 18px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    filter: blur(20px);
  }

}

.no-video__text__play {
  z-index: 1000000;
  filter: invert(100%);
}

.no-video__text {
  color: white;
  font-size: 1.6rem;
  z-index: 1000000;
}