.onboarding {

  margin: auto;
  border-radius: 15px;
  overflow: hidden;
  border: none;

  box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.45);


  & tr {
    border-spacing:0 15px;
    border-collapse:separate;
  }

  & tr:nth-child(even) {background-color: #f2f2f2;}
  & tr:nth-child(odd) {background-color: white;}



  & th {
    border: none;

  }

  & td {
    border: none;


  }
}