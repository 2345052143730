
.button--linkedin {
  display: inline-block;
  padding: 14px 18px;
  border-style: none;
  border-radius: 30px;
  background-color: #3898ec;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  cursor: pointer;
  margin:3px;

  &:disabled{
    opacity: 0.4;
  }
}

.button--linkedin--transparent{
  opacity:0.6;
}

.button--black {
  display: inline-block;
  padding: 10px 22px;
  border-style: none;
  border-radius: 4px;
  background-color: black;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  cursor: pointer;
  margin: 3px;

  &:disabled {
    opacity: 0.4;
  }
}

.candidate-evaluate__container {
  position: relative;
  width: 95%;
  max-width: 890px;
  border-radius: 4px;
  background-color: white;
  margin: 5px;
  padding: 10px;
}

.header {

  height: 210px;

  @include smartphones-only {
    height: 260px;
  }

  &__avatar-container {

    background-color: white;
    width: 150px;
    height: 150px;
    border-radius: 75px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    & img {
      object-fit: cover;
    }
  }

  &__avatar {

    width: 130px;
    height: 130px;
    border-radius: 65px;
    object-fit: cover;
  }
}

.header__name {
  font-family: 'geomanistbold', 'Lato', sans-serif;
  font-size: 1.2rem;
  margin: 0 auto 10px auto;
  width: 90%;
}


.evaluation {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

}


.evaluation__comment {
  width: 85%;
  max-width: 520px;
  height: 100px;
  border-radius: 15px;
  text-align: left;
  padding: 20px 35px;
  border: 3px solid black;

}




.space-flex--center {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  &--wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
}

.space-flex--up {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;

  &__cellphone {
    @include smartphones-only {
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
    }
  }
}

.results-V2 {

  background-color:  #44c5f3;
  color: white;
  min-height: 100px;
  border-radius: 60px;
  margin: 10px;

  padding: 20px 0;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: flex-start;

  &__item {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    &__title {
      margin-bottom: 8px;
    }

    &__value {
      background-color: white;

      width: 90px;
      height: 60px;
      border-radius: 30px;

      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;

      font-size: 0.9rem;
      font-family: 'geomanistbold', 'Lato', sans-serif;
    }

    &__bar {
      position: relative;
      background-color: #fff7;
      width: 80px;
      height: 14px;
      border-radius: 7px;
      margin-top: 10px;
      overflow: hidden;


      &--selected {
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
        height: 14px;

        &--0 {
          width: 0;
        }
        &--10 {
          width: 10%;
        }
        &--20 {
          width: 20%;
        }
        &--30 {
          width: 30%;
        }
        &--40 {
          width: 40%;
        }
        &--50 {
          width: 50%;
        }
        &--60 {
          width: 60%;
        }
        &--70 {
          width: 70%;
        }
        &--80 {
          width: 80%;
        }
        &--90 {
          width: 90%;
        }
        &--100 {
          width: 100%;
        }

      }
    }
  }
}

.results-v2__title {
  font-family: 'geomanistblack', 'Lato', sans-serif;
  color: #404253;
  font-size: 1.3rem;
  text-align: center;
}

.results-v2__answer {

  margin: 10px;
  background-color: #f6f2ed;
  padding: 5px;
  border-radius: 4px;

  &__question {
    margin: 10px auto;
    font-size: 1.1rem;
    text-align: center;
  }
}

.results-v2__skill {
  margin: 10px;
  background-color: #f6f2ed;
  padding: 8px;
  border-radius: 20px;

  &--green {
    background-color: #41e39e;
  }

  &--red {
    background-color: lighten(#E21C64, 20%);
  }
}

.candidate-answers {

  &__question {
    padding: 5px 10px;
    background-color: #404253;
    color: white;
    font-size: 1.1rem;
    margin: 20px 0;
  }

  &__answer {

    padding: 7px 10px;
    border-radius: 4px;

    &--good {
      background-color: #a1c4e4;
      color:white;
    }

    &--wrong {
      background-color: black;
      color:white;
    }

    &--grey {
      background-color: #ddd;
    }
  }
}
