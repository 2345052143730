.candidate-page__container{
  display:flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  margin: auto;

  @include smartphones-and-tablets-only {
    display:flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
}

.candidate-page__left, .candidate-page__right {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  //align-items: center;

  @include smartphones-and-tablets-only {
    width: 95%;
  }
}

.candidate-page__left {
  align-items: flex-end;
  @include smartphones-and-tablets-only {
    align-items: center;
  }
}

.candidate-page__right {
  align-items: flex-start;
  @include smartphones-and-tablets-only {
    align-items: center;
  }
}

.header--up {
  position: relative;
  top: -75px;
  margin:5px auto;
}

.candidate-page__avatar-container{
  border-radius: 120px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  & img {
    object-fit: cover;
  }
}

.candidate-page__avatar {

  width: 190px;
  height: 190px;
  border-radius: 95px;
  object-fit: cover;
}



.candidate-page__title{
  font-weight: bold;
  margin-bottom: 10px;
}

.candidate-page__list-logo{
  height: 6px;
  width:6px;
  border-radius: 3px;
  background-color: black;
  margin:6px;
}

.candidate-page__evaluation-step{
  height: 10px;
  width:10px;
  border-radius: 5px;
  background-color: darkgrey;
  margin:5px;
}

.candidate-page__evaluation-step--selected{
  height: 10px;
  width:10px;
  border-radius: 5px;
  background-color: grey;
  margin:5px;
}


.candidate-page__tchat{
  //height: 400px;
  width:98%;
}

.candidate-page__answers{
  height:300px;
  overflow-y: scroll;
  margin:20px;
}

.candidate-page__tchat-container{
  width: 100%;
  height:260px;
  border:none;
  //background-color: #a1c4e4;
  background-color: white;
  border-radius: 10px;
  margin:2px;
  overflow-y: scroll;
}

.candidate-page__tchat-input{
  width: 60% !important;
  max-width: 500px;
  //height:60px !important;
  border: 1px solid #bbb !important;
  //background-color: #a1c4e4 !important;
  background-color: white;
  border-radius: 30px !important;
  margin: 2px!important;
  padding: 10px 18px;
}

.candidate-page__tchat-input2{
  width: 90% !important;
  max-width: 500px;
  //height:60px !important;
  border: 1px solid #ccc !important;
  background-color: white !important;
  border-radius: 30px !important;
  margin: 8px auto !important;
  padding: 14px 18px;
  @include smartphones-only {
    width: 98% !important;
  }
}

.candidate-page__tchat-input2--red{

  border:1px solid red !important;

}

.candidate-page__from-input{
  width: 30% !important;
  max-width: 500px;
  //height:60px !important;
  border:none !important;
  background-color: #a1c4e4 !important;
  border-radius: 30px !important;
  border: 1px solid #ccc !important;
  background-color: white !important;
  margin:2px!important;
  padding: 10px 18px;
}

.candidate-page__note-input{
  width: 45% !important;
  max-width: 500px;
  //height:60px !important;
  border:none !important;
  background-color: #a1c4e4 !important;
  border: 1px solid #ccc !important;
  background-color: white !important;
  border-radius: 30px !important;
  margin:2px!important;
  padding: 10px 18px;
}
.candidate-page__tchat-btn{
  width: 100px ;
  border:none ;
  background-color: #a1c4e4 ;
  border-radius: 30px ;
  margin:2px;
  color:white;
  font-weight: bold;
  padding: 10px 18px;
}

.candidate-page__tchat-title{
  font-weight: bold;
}

.candidate-page__tchat-item{
  margin:5px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.candidate-page__tchat-avatar{
  width:40px;
  height: 40px;
  border-radius: 20px;
  font-size: small;
  font-weight: bold;
  border: 2px solid black;
  margin:10px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

}

.candidate-page__tchat-avatar-img{
  width:40px;
  height: 40px;
  border-radius: 20px;
}

.candidate-page__button--switch{

    background-color: transparent;
    border: none;
    margin: 10px;

    & img {
      width: 15px;
      height: 15px;
    }
  }

.candidate-page__button--previous{
  transform: rotateZ(180deg);
}

.candidate-page__space-between{
  display:flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.candidate-page__matching-final{
  color: #2ec43d;
  font-weight: bolder;
  font-size: 1.6rem;
  @include smartphones-only {
    font-size: 1.1rem;
  }
}

.candidate-page__top{
  display:flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin:0px 20px;
  @include smartphones-and-tablets-only{
    display:flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
}

.candidate-page__informations-container{
  display:flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  //margin:0px 20px;

  & > div {
    margin-right: 30px;
  }

  @include smartphones-and-tablets-only{
    display:flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
  }
}

/**
=============================================
 */

.flash-card-container {
  position: relative;
  width: 95%;
  max-width: 890px;
  border-radius: 18px;
  background-color: white;
  margin: 5px;
  padding: 10px;
}

.flash-card-container--video {
  position: relative;
  width: 95%;
  //max-height: 500px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.flash-card-container > video {
  position: relative;
  width: 95%;
  max-height: 550px;
}

.flash-card-steps-container{
  position: relative;
  width: 95%;
  border-radius: 18px;
  background-color: white;
  margin: 0 auto;
  padding: 10px;
}

.candidate-page__steps{
  display:flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: flex-start;

  @include smartphones-only {
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
}

.candidate-page__step{
  flex: 1;
  display:flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  //width:200px;
  //height:150px;
  padding:15px;
}

.candidate-page__step-circle {
  border-radius: 50%;
  background-color: #bfb1b1;
  //margin-bottom: 7px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.candidate-page__step-circle1{
  height: 24px;
  width: 24px;
}

.candidate-page__step-circle2{
  height: 16px;
  width: 16px;
}

.candidate-page__step-circle3{
  height: 8px;
  width: 8px;
}

.circle--green {
  background-color: #37d23f;
}

.circle--red {
  background-color: #e3540e;
}

.circle--white {
  background-color: white;
}

.candidate-page__step-title{
  margin-top: 7px;
  text-align: center;
  font-size: 0.9rem;
  font-weight: bolder;
  color: #999;
  //height:100px;
}

.candidate-page__step-date{
  text-align: center;
  font-size: 0.9rem;
  color: #999;
  //height:100px;
}

.form_gdpr {
  text-align: left;
}

.form_checkbox {
  margin-right: 10px !important;
  width: 18px;
  height: 18px;
  position: relative;
  top: 3px;
}