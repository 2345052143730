
.notification {

  position: fixed;
  bottom:0;
  left:0;
  width: 100%;
  //padding: 11px;

  z-index: 10000000;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  &__text {
    position: relative;
    //background-color: #41cbe3;
    padding: 20px;
    width: 100%;
    border-radius: 10px;
    animation: notification-fade-out 3.5s forwards;

    &--success {
      background-color: #41e39e;
    }
    &--information {
      background-color: #41cbe3;
    }
    &--warning {
      background-color: #e39a41;
    }
    &--error {
      background-color: #e34167;
    }
  }
}

@keyframes notification-fade-out {
  0% { opacity: 0; top: 0px;}
  5% { opacity: 1; top: 0px; }
  95% { opacity: 1; top: 0px;}
  100% { opacity: 0; top: -50px;}
}